import { useEffect } from 'react';
import { useSearchParams } from 'next/navigation';

import { googleClassroomContextManager } from '../GoogleClassroomContextManager';

/**
 * 구글 클래스룸에서 진입 후 인증이 되지 않아 로그인 페이지로 리다이렉트된 경우,
 * 로그인 완료 후 처음 진입 시 갖고 있던 QueryString으로 구글 클래스룸 Context 생성을 재시도합니다.
 */
export function useInitGoogleClassroomContextForRedirectPage() {
  const searchParams = useSearchParams();

  useEffect(() => {
    const callbackUrl = searchParams.get('callbackUrl');
    if (callbackUrl) {
      // e.g. '/google-classroom/teacher-landing?gc_landingPageType=AttachmentSetup&addOnToken=AJaYm_3wP783lBQyAG0zoG1riVtr%3A1734079582055&courseId=731346739420&postId=732858298583&itemId=732858298583&itemType=courseWork&login_hint=114389486751463934642'
      if (decodeURIComponent(callbackUrl).startsWith('/google-classroom')) {
        const searchForCallbackUrl = `?${callbackUrl.split('?')[1]}`;
        googleClassroomContextManager.initializeLandingPageContext(
          new URLSearchParams(searchForCallbackUrl),
        );
      }
    }
  }, [searchParams]);
}
