import { ComponentPropsWithoutRef, forwardRef, ReactNode } from 'react';
import { Button } from '@zep/ui_v3';
export const OauthButton = forwardRef<HTMLButtonElement, ComponentPropsWithoutRef<'div'> & {
  logo: ReactNode;
}>((props, ref) => {
  const {
    logo,
    ...rest
  } = props;
  return <Button ref={ref} size={'lg'} asChild intent={'neutral'} variant={'outlined'}>
      <div className={'w-full cursor-pointer px-lg'} {...rest}>
        {logo}
        <span className={'text-body-lg-sb text-default'}>{props.children}</span>
      </div>
    </Button>;
});
OauthButton.displayName = 'OauthButton';