import { useEffect } from 'react';

const MESSAGE_TYPE = 'google-oauth-success';

export function useGoogleOAuthSuccessEffect(
  callback?: (props: {
    zepServerToken: string;
    googleAccessToken: string;
  }) => Promise<void>,
) {
  useEffect(() => {
    const handleMessage = async (
      event: MessageEvent<{
        type: string;
        zepServerToken: string;
        googleAccessToken: string;
      }>,
    ) => {
      const { type, zepServerToken, googleAccessToken } = event.data;
      if (type === MESSAGE_TYPE) {
        callback?.({ zepServerToken, googleAccessToken });
      }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [callback]);
}

useGoogleOAuthSuccessEffect.MESSAGE_TYPE = MESSAGE_TYPE;
