import { useEffect, useState } from 'react';

/**
 * useDelayedLoading
 * @param isLoading - 현재 로딩 상태 (React Query의 isLoading 또는 isFetching)
 * @param threshold - 로딩 지연을 판단하는 최소 시간 (기본값: 1000ms)
 * @returns isDelayed - 지연된 로딩 상태를 반환
 */
export const useDelayedLoading = (
  isLoading: boolean,
  threshold: number = 1000,
): boolean => {
  const [isDelayed, setIsDelayed] = useState<boolean>(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isLoading) {
      timer = setTimeout(() => {
        setIsDelayed(true);
      }, threshold);
    } else {
      setIsDelayed(false);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isLoading, threshold]);

  return isDelayed;
};
