import { ComponentPropsWithoutRef, ReactElement } from 'react';
import { cn } from '@zep/utils';
export function getCenterLayout(page: ReactElement) {
  return <CenterLayout data-sentry-element="CenterLayout" data-sentry-component="getCenterLayout" data-sentry-source-file="getCenterLayout.tsx">{page}</CenterLayout>;
}
export const CenterLayout = (props: ComponentPropsWithoutRef<'div'>) => {
  const {
    className,
    children,
    ...rest
  } = props;
  return <div className={cn('pointer-events-auto p-md md:p-2xl fixed z-10  flex h-dvh w-full flex-col items-center justify-center bg-neutral', className)} {...rest} data-sentry-component="CenterLayout" data-sentry-source-file="getCenterLayout.tsx">
      {children}
    </div>;
};