import { useCallback, useRef } from 'react';

interface PopupOptions {
  width?: number;
  height?: number;
  top?: number;
  left?: number;
}

export function usePopupWindow() {
  const popupRef = useRef<Window | null>(null);

  const openPopup = useCallback(
    (url: string, name: string, options?: PopupOptions) => {
      const { width = 500, height = 600 } = options ?? {};
      const top = options?.top ?? window.screen.height / 2 - height / 2;
      const left = options?.left ?? window.screen.width / 2 - width / 2;

      const features = `left=${left},top=${top},width=${width},height=${height}`;
      popupRef.current = window.open(url, name, features);
      if (!popupRef.current) {
        alert('Popup was blocked! Please allow popups and try again.');
      }
    },
    [],
  );

  const closePopup = useCallback(() => {
    if (popupRef.current && !popupRef.current.closed) {
      popupRef.current.close();
    }
  }, []);

  return {
    popupRef,
    openPopup,
    closePopup,
  };
}
