import React from 'react';
import { googleClassroomContextManager } from '@zep/shared/googleClassroom';
import { useTranslation } from 'next-i18next';
import { usePopupWindow } from '../hooks';
import { generateGoogleOAuthUrl } from './generateGoogleOAuthUrl';
import { OauthButton } from './OauthButton';
import { useGoogleOAuthSuccessEffect as useOnGoogleOAuthSuccess } from './useOnGoogleOAuthSuccess';
interface Props {
  callbackUrl: string;
  onSuccess?: (props: {
    zepServerToken: string;
    googleAccessToken: string;
  }) => Promise<void>;
}

/**
 * 1) Opener(젭퀴즈 클라이언트) Window는 Google 로그인 페이지(accounts.google.com/o/oauth2/~)를 팝업으로 띄움
 * 2) 로그인을 완료하면 팝업 내에서 퀴즈 서버로 요청을 보냄(serverGoogleCallback) @file generateGoogleOAuthUrl.ts
 * 3) 퀴즈 서버는 팝업으로 Redirect(-> /auth/google-callback) Response를 줌. 이때 QueryParam에 토큰 발급을 위한 임시 코드를 넣어줌. @file generateGoogleOAuthUrl.ts
 * 4) 팝업은 /auth/google-callback에서 임시 코드와 uuid를 가지고 token, googleAccessToken를 발급함. @file GoogleCallbackPage.tsx, generateGoogleOAuthUrl.ts
 * 5) 팝업은 Opener에게 postMessage로 발급 받은 토큰을 전달. @file GoogleCallbackPage.tsx
 * 6) Opener는 발급 받은 토큰을 이용해서 인증 절차를 완료. @file LoginPageView.tsx
 */
export function GoogleLoginButton({
  callbackUrl,
  onSuccess
}: Props) {
  const {
    t
  } = useTranslation(['common']);
  const {
    openPopup,
    closePopup
  } = usePopupWindow();
  const showGoogleOAuthLoginPopup = () => {
    openPopup(generateGoogleOAuthUrl({
      callbackUrl,
      scope: googleClassroomContextManager.getGoogleOAuthScope()
    }), 'google-oauth-popup');
  };
  useOnGoogleOAuthSuccess(async ({
    zepServerToken,
    googleAccessToken
  }) => {
    await onSuccess?.({
      zepServerToken,
      googleAccessToken
    });
    closePopup();
  });
  return <OauthButton onClick={() => {
    showGoogleOAuthLoginPopup();
  }} logo={<img className={'size-[16px]'} src="/assets/Icon_google.png" alt="google" />} data-sentry-element="OauthButton" data-sentry-component="GoogleLoginButton" data-sentry-source-file="GoogleLoginButton.tsx">
      {t('login.google.button')}
    </OauthButton>;
}