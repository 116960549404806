import React from 'react';
import { useTranslation } from 'next-i18next';
import { OauthButton } from './OauthButton';
declare global {
  interface Window {
    grecaptcha: any;
  }
}
export const clientID = {
  WHALE: 'zKSF55eBgJswaL4LhGbW'
};
const REDIRECT_URI = process.env.NEXT_PUBLIC_SERVER_BASEURL + '/api/auth/whalespace/callback';

/**
 *  WhaleSpace 로그인 버튼을 누르면
 *  -> 웨일스페이스 로그인페이지로 이동
 *  -> 웨일스페이스가 서버 api url 로(redirect url) 결과를 보내줌
 *  -> 서버  api url -> /auth/whalespace-callback 경로로 redirect 시켜줌
 *    (token 값을 주고 해당 페이지에서 로그인 처리를 해주고있습니다.)
 *   !! 로컬에서는 개발할수없습니다(서버에서 지정된 dev,stage,live 기준으로만 동작합니다.)
 * */
export function WhaleSpaceLoginButton() {
  const {
    t
  } = useTranslation(['common']);
  const signIn = () => {
    window.grecaptcha?.ready(() => {
      window.grecaptcha.execute('6Lfvpk4dAAAAAH8NoQyuJE_ovaUyFVJ1kJP6nSOT', {
        action: 'signin'
      }).then((state: string) => {
        window.location.href = 'https://auth.whalespace.io/oauth2/v1.1/authorize?response_type=code&' + `state=${encodeURIComponent(state)}&` + `redirect_uri=${REDIRECT_URI}&` + `client_id=${clientID.WHALE}&` + 'scope=https%3A//whalespace.io/directory/user.profile.readonly';
      });
    });
  };
  return <OauthButton logo={<img className={'size-[18px]'} src="/assets/icon_whalespace.png" alt="google" />} onClick={() => signIn()} data-sentry-element="OauthButton" data-sentry-component="WhaleSpaceLoginButton" data-sentry-source-file="WhaleSpaceLoginButton.tsx">
      {t('common:login.whalespace.button')}
    </OauthButton>;
}