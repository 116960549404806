import { ReactNode } from 'react';
import { NoSSR } from '@zep/components';
import { CenterLayout } from '@zep/layout/getCenterLayout';
import { InAppLoginPageView, LoginPageView } from '@zep/module/auth';
import { getDefaultStaticProps, isInAppBrowser } from '../utils';
export default function LoginPage() {
  return <NoSSR data-sentry-element="NoSSR" data-sentry-component="LoginPage" data-sentry-source-file="login.tsx">
      {isInAppBrowser() ? <InAppLoginPageView /> : <LoginPageView />}
    </NoSSR>;
}
export const getStaticProps = getDefaultStaticProps;
LoginPage.getLayout = (page: ReactNode) => {
  return <CenterLayout className={'!mb:pb-[80px] !pb-[80px]'}>{page}</CenterLayout>;
};