import { KeyboardEvent, useCallback } from 'react';

type HandleEnterKeyCallback = () => void;

export const useHandleEnterKey = () => {
  return useCallback(
    (
      event: KeyboardEvent<HTMLInputElement>,
      callback: HandleEnterKeyCallback,
    ) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        callback();
      }
    },
    [],
  );
};
