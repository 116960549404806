import { createQueryString } from '@toss/utils';
import { v4 as uuidv4 } from 'uuid';

const CLIENT_GOOGLE_CALLBACK_PATHNAME = '/auth/google-callback';

export function generateGoogleOAuthUrl({
  callbackUrl,
  scope,
}: {
  callbackUrl: string;
  scope: string;
}) {
  const serverGoogleCallback = `${process.env.NEXT_PUBLIC_SERVER_BASEURL}/api/auth/google/callback`;
  return `https://accounts.google.com/o/oauth2/v2/auth${createQueryString({
    client_id: process.env.NEXT_PUBLIC_GOOGLE_OAUTH_ID,
    redirect_uri: serverGoogleCallback,
    response_type: 'code',
    scope,
    access_type: 'offline',
    prompt: 'consent',
    state: JSON.stringify({
      /**
       * 서버에서 사용하는 값들 (Key 변경에 유의해야 함)
       */
      origin: window.location.origin,
      redirectPathname: CLIENT_GOOGLE_CALLBACK_PATHNAME,
      /**
       * 젭퀴즈 서버에서 리다이렉트할 때 내려주는 임시 코드(유효기간 5분)를 가지고
       * token, googleAccessToken를 발급할 때 함께 사용하는 uuid
       * (uuid + code를 모두 요구함으로써 code가 탈취되어도 token를 발급할 수 없도록 보안성을 높임)
       */
      uuid: uuidv4(),

      /**
       * 클라이언트에서 사용하는 값
       */
      callbackUrl: encodeURIComponent(callbackUrl),
    }),
  })}`;
}
